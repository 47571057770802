@import "@wolfiesports/srm-component/dist/styles/colors";

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 32px;
  color: $white;
  background-color: $black;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 49px;
    z-index: 1;
  }

  .left-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    img {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .right-content {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: flex-end;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;
    }

    .underline {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
