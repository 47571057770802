@import "@wolfiesports/srm-component/dist/styles/colors";

.favorite-games {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  &--edit {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 20px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__container {
    display: flex;
    margin-bottom: 16px;
    align-items: baseline;
    width: 100%;
    justify-content: center;

    &--title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: $grey2;
    }

    &--list {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;

      &--element {
        width: 20%;
        background: $grey1;
        border-radius: 50%;
        max-width: 56px;
        margin: 12px;
        position: relative;
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        img {
          position: absolute;
          width: 50%;
          height: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.active {
          background: $green;
          border: 1px solid $green;
          box-shadow: 0 0 4px $green;
          transition: 0.2s;

          &::before {
            content: "";
            background: linear-gradient(
              180deg,
              #000000 0%,
              rgba(0, 0, 0, 0.3) 100%
            );
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &--save {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.no-click {
  cursor: default;
}
