.contact-page-container {
  text-align: center;
  padding: 2rem;
  background-image: url(https://bundle.next.wolfiesports.com/webapp-gameaccount/static/media/game-account-background.3ce66e4709e1519b84dc.jpeg);
  background-position: 50%;
  background-size: cover;
  height: 100%;
  min-height: calc(100vh - 48px - 56px);
}

.discord-block {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%; 
  margin-top: 2rem; 
  justify-content: center; 
  padding: 20px; 
  max-width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 2rem;
  justify-items: center;
}

.email-block,
.help-center {
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px; 
  text-align: center;
  color: white; 
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: center; 
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .email-block,
  .help-center {
    padding: 15px;
    max-width: 100%; 
  }

  .buttons {
    flex-direction: row; 
    justify-content: center;
  }

  .discord-block {
    margin-top: 3rem;
  }
}

@media (max-width: 480px) {
  .grid {
    gap: 10px;
  }
}
