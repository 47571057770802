@import "@wolfiesports/srm-component/dist/styles/colors";
.register-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  min-height: calc(100vh - 48px - 56px); // substract header & footer

  a {
    color: $electric-lime;
  }
  .login-title {
    color: $electric-lime;
  }

  .register-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 45%;
    margin-bottom: 16px;

    @media screen and (max-width: 1000px) {
      width: 75%;
      margin-top: 50px;
    }

    .opt-in-check {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .login-subtitle {
      color: $electric-lime;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
