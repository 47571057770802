@import "@wolfiesports/srm-component/dist/styles/colors";

.plan-item-container {
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 150px;
  justify-content: center;
  gap: 8px;
  padding: 10px 16px;
  margin: 0 10px;
  text-align: center;
  user-select: none;
  background:
    linear-gradient(#292d30, #292d30) padding-box,
    linear-gradient(
        115.49deg,
        rgba(254, 92, 43, 0) 26.54%,
        #fe5c2b 57.93%,
        #ffde00 88.3%
      )
      border-box;
  border-radius: 10px;
  border: 2px solid transparent;

  &.selected {
    background:
      linear-gradient(#292d30, #292d30) padding-box,
      linear-gradient(0deg, $orange-outrageous 0.03%, $yellow-school 99.97%)
        border-box;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .already-subscribed {
    font-weight: bold;
    color: $red;
  }

  @media screen and (max-width: 700px) {
    width: 250px;
    margin: 0;
  }

  p,
  h6 {
    margin: 0;
  }

  .color-premium {
    color: $premium-gradient;
    background: -webkit-linear-gradient(
      0deg,
      $orange-outrageous 0.03%,
      $yellow-school 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
