@import "@wolfiesports/srm-component/dist/styles/colors";

@font-face {
  font-family: "Eurostile";
  src: url("./assets/fonts/eurostile.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

#root {
  width: 100%;
  min-height: 100vh;
  background-color: $grey3;
}

body {
  font-family: "mollenregular", "Montserrat", sans-serif;
  color: $grey5;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.015em;
  line-height: 1.5;
}

.nc-page-title {
  font-family: "mollenregular", "Montserrat", sans-serif;
  font-weight: 700;
  color: $grey5;
  text-transform: uppercase;
  font-size: 22px;

  &:after {
    content: "";
    display: block;
    width: 65px;
    border-bottom: 2px solid $primary-color;
  }
}

.page-layout {
  display: flex;
  flex-direction: column;
  .__webapp-menu {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;

    @media screen and (max-width: 768px) {
        top: calc(100% - 48px);
    }
  }
  .router-layout {
    height: 100%;
    min-height: calc(100vh - 48px - 56px); // substract header & footer
    margin: 48px 0 0; // menu
    width: 100%;
    @media screen and (max-width: 768px) {
      top: calc(100% - 48px);
      margin: 0 0 48px; // menu
    }
  }
}
