@import "../../styles/mixins.scss";
@import "~bootstrap/scss/bootstrap-reboot";

.premium-page-container {
  .bg-image-container {
    width: 100%;
    height: 800px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    top: -470px;
    background: linear-gradient(
      360deg,
      #292d30 70%,
      rgba(26, 26, 26, 0.4) 100%
    );
    width: 100%;

    @media screen and (max-width: 1100px) {
      background: linear-gradient(
        360deg,
        #292d30 85%,
        rgba(26, 26, 26, 0.4) 100%
      );
    }
    @media screen and (max-width: 400px) {
      top: -800px;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 52px;
      margin-top: 50px;
      width: 100%;
      text-align: center;
      h4,
      h3 {
        color: white;
      }
    }

    .premium-presentation-container {
      display: flex;
      flex-direction: row;
      gap: 85px;
      justify-content: center;
      margin-top: 100px;

      @media screen and (max-width: 1600px) {
        display: flex;
        flex-wrap: wrap;
      }
      .premium-item {
        display: flex;
        flex-direction: column;
        gap: 30px;
        border: 1px solid #ffffff;
        border-radius: 8px;
        padding: 30px;
        width: 310px;

        .top-image-container {
          width: 100%;
          height: 150px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        .premium-item-price {
          display: flex;
          flex-direction: row;
          align-self: center;
          gap: 5px;
          align-items: baseline;

          h5,
          p {
            margin-bottom: 0 !important;
            color: white;
          }
        }

        .premium-item-list {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .premium-list-items {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            gap: 5px;

            .dot {
              width: 8px;
              height: 8px;
              border-radius: 100%;
              background: $premium-gradient;
            }
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
