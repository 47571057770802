@import "@wolfiesports/srm-component/dist/styles/colors";

.terms-of-use {
  background-color: $grey3;
  color: $white;
  padding: 70px 50px;

  a {
    color: $electric-lime;
  }
}
