@import '@wolfiesports/srm-component/dist/styles/colors';
@import '@wolfiesports/srm-component/dist/styles/variables';
@import '../../styles/mixins.scss';

.user-profile {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 48px - 56px); // substract header & footer
    overflow: auto;

    .mask-icon {
        background-color: $white;
    }

    .user-profile-wrapper {
        z-index: 2;
        position: relative;
    }

    .header {
        height: 304px;
        width: 100%;

        @include breakpoint(tablet) {
            height: 202px;
            flex-direction: column;
        }

        .upload-media {
            z-index: 6;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%; 
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            @include breakpoint(tablet){
                top: 20%;
            }
        }

        &.owner {
            cursor: pointer;

            &:hover::before {
                z-index:2;
                position: absolute;
                height: 304px;
                content: '';
                display: block;
                width: 100%;
                background: linear-gradient(180deg,
                        rgba(0, 0, 0, 0.4),
                        rgba(0, 0, 0, 0.8));

                @include breakpoint(tablet) {
                    height: 202px;
                    flex-direction: column;
                }
            }
        }

        .banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .edit-icon {
            z-index: 3;
            cursor: pointer;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 25%;
            left: 50%;

            @include breakpoint(tablet){
                top: 4%;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 304px;
            left: 0;
            height: 2px;
            width: 100%;
            background: linear-gradient(180deg, #FFE350 0%, #FFF2B0 46.15%, #BC9E00 100%);
        }

        @include breakpoint(tablet) {
            &:after {
                top: 202px;
            }
        }
    }

    .main-content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        .left-panel {
            background: linear-gradient(1turn,
                    $grey2 50%,
                    rgba(26, 26, 26, 0.7));
            width: 450px;
            min-width: 450px;

            min-height: calc(100vh - 304px - 48px - 56px);

            display: flex;
            flex-direction: column;

            @include breakpoint(tablet) {
                width: 100%;
                min-width: auto;
                min-height: auto;
            }

            .user-picture {
                z-index:4;
                position: absolute;
                height: 200px;
                width: 200px;
                display: flex;
                align-items: center;
                top: 200px;
                left: 125px;
                margin-bottom: 40px;
                justify-content: center;

                &.owner {
                    cursor: pointer;
                }

                .upload-media {
                    z-index: 5;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }

                @include breakpoint(mobile) {
                    top: 100px;
                    left: calc(50% - 100px);
                }

                @include breakpoint(tablet) {
                    top: 100px;
                    left: calc(50% - 100px);
                }
            }

            .team-informations {
                margin-top: 150px;
            }
        }

        .right-panel {
            width: 100%;
            background: linear-gradient(1turn, $grey3 50%, rgba(0, 0, 0, 0.4));
            padding-top: 24px;
            padding-left: 32px;
            padding-right: 32px;
            padding-bottom: 24px;

            .user-teams{
                margin-top: 10px;
                margin-bottom: 20px;
            }

            @include breakpoint(tablet) {
                width: 100%;
                min-width: auto;
            }

            @include breakpoint(mobile) {
                padding-left: 10px;
                padding-right: 10px;
            }

        }
    }
}