@import "@wolfiesports/srm-component/dist/styles/colors";

.user-settings-page-container {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 48px - 56px);
  overflow: auto;
  color: white;
  padding: 70px 32px;

  background-attachment: fixed;
  background-image: url(https://bundle.next.wolfiesports.com/webapp-gameaccount/static/media/game-account-background.3ce66e4709e1519b84dc.jpeg);
  background-position: 50%;
  background-size: cover;
  height: 100%;
  min-height: calc(100vh - 48px - 56px); // substract header & footer
  position: relative;
  width: 100%;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 15px 0;
    align-items: center;
    gap: 30px;
  }

  .left-content {
    width: 340px;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    text-align: center;
    .left-menu-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      .account-settings-menu-item {
        h6 {
          width: fit-content;
          border-bottom: 2px transparent solid;
          transition: 0.2s;
          color: $grey5;
          &:hover {
            cursor: pointer;
            transition: 0.2s;
            border-bottom: 2px $electric-lime solid;
            color: $white;
          }
        }
        &.active {
          h6 {
            color: $white;
            transition: 0.2s;
            border-bottom: 2px $electric-lime solid;
          }
        }
      }
    }
  }

  .right-content {
    width: 50%;
    padding-left: 15px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0 20px;
    }

    .account-settings-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .datepicker-container {
        display: none;
      }
      .title {
        h4 {
          font-weight: bold;
          margin-bottom: 10px;
        }
        h5 {
          color: $electric-lime;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-styles {
          display: flex;
          flex-direction: column;
          gap: 2px;
          width: 100%;
        }

        .input-styles-checkbox {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-self: center;

          h6 {
            margin-bottom: 0;
          }

          .custom-checkbox {
            display: inline-block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: 22px;
            user-select: none;

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: #eee;
              border-radius: 5px;

              &:hover {
                background-color: #ccc;
              }
            }

            input:checked ~ .checkmark {
              background-color: $electric-lime;
            }

            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            input:checked ~ .checkmark:after {
              display: block;
            }

            .checkmark:after {
              -webkit-transform: rotate(38deg);
              -ms-transform: rotate(38deg);
              transform: rotate(38deg);
              left: 9px;
              top: 4px;
              width: 7px;
              height: 14px;
              border: solid $black;
              border-width: 0 2px 2px 0px;
            }
          }
        }

        .inline-form {
          display: flex;
          flex-direction: row;
          gap: 16px;

          @media screen and (max-width: 1000px) {
            flex-direction: column;
          }
        }

        .game-selection {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          overflow: auto;

          .game-item {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;

            .game-card {
              margin-top: 10px;
              margin-left: 10px;
              height: 200px;
              width: 140px;
              border-radius: 10px;
              border: 1px $grey5 solid;
              transition: 0.2s;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;

              @media screen and (max-width: 1000px) {
                height: 100px;
                width: 75px;
              }

              &:hover {
                transition: 0.2s;
                border: 1px $electric-lime solid;
              }
            }

            &.active {
              .game-card {
                transition: 0.2s;
                border: 2px $electric-lime solid;
                box-shadow: 0px 0px 13px $electric-lime;
              }
            }
          }
        }

        .profile-selection {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          overflow: auto;

          .profile-item {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .profile-card {
              height: 150px;
              width: 150px;
              border-radius: 100%;
              border: 2px $grey5 solid;
              margin: 13px;

              @media screen and (max-width: 1000px) {
                height: 95px;
                width: 95px;
              }

              &:hover {
                transition: 0.2s;
                border: 2px $electric-lime solid;
              }
            }

            &.active {
              .profile-card {
                transition: 0.2s;
                border: 2px $electric-lime solid;
                box-shadow: 0px 0px 13px $electric-lime;
              }
            }
          }
        }

        .banner-selection {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          @media screen and (max-width: 1000px) {
            justify-content: center;
          }

          .banner-item {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .banner-card {
              height: 200px;
              width: 650px;
              border-radius: 10px;
              border: 1px $grey5 solid;
              transition: 0.2s;
              margin: 10px;

              @media screen and (max-width: 1000px) {
                height: 100px;
                width: 350px;
              }

              @media screen and (max-width: 475px) {
                height: 50px;
                width: 175px;
              }
              &:hover {
                transition: 0.2s;
                border: 1px $electric-lime solid;
              }
            }

            &.active {
              .banner-card {
                transition: 0.2s;
                border: 1px $electric-lime solid;
                box-shadow: 0px 0px 10px $electric-lime;
              }
            }
          }
        }

        .currency-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .item {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }
        }

        .send-button {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }

    .error-message {
      font-weight: bold;
      color: $red;
    }

    .success-message {
      font-weight: bold;
      color: $electric-lime;
    }
  }
}

.premium-settings-page-container {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 40px;

  @media screen and (max-width: 1000px) {
    padding: 10px;
  }

  .add-voucher-form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 300px;

    .form-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }

  .history-table-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 980px;

    @media screen and (max-width: 1000px) {
      width: 350px;
    }

    .premium-history-table {
      width: 100%;
      border-collapse: collapse;
      color: white;

      th {
        background-color: #1a1a1a;
        padding: 12px 16px;
        text-align: left;
        font-weight: normal;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }

      td {
        background-color: #2a2a2a;
        padding: 12px 16px;
        border-top: 1px solid #1a1a1a;
      }

      tr:hover {
        background-color: #323232;
      }

      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 10px;
            }
            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }

  .cancel-dialog-button-part {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 32px;
    justify-content: center;
  }
}

.country-select-container {
  height: 100%;

  div {
    height: 100%;
  }

  select {
    height: 100%;
    border: 1px solid $grey4;
    height: 40px;
  }
}
