@import "@wolfiesports/srm-component/dist/styles/colors";

.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: calc(100vh - 48px - 56px); // substract header & footer

  .login-title {
    color: $electric-lime;
  }

  .login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 45%;

    @media screen and (max-width: 1000px) {
      width: 75%;
    }

    .login-subtitle {
      color: $electric-lime;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .forgot {
    color: $electric-lime;
    cursor: pointer;
  }

  .button-part {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
