@import '@wolfiesports/srm-component/dist/styles/variables';
@import "@wolfiesports/srm-component/dist/styles/colors";
@import "@wolfiesports/srm-component/dist/styles/mixins";

.checkin-broadcast-container {
    top: 60px;
    left: 0;
    padding-left: 96px;
    padding-right: 48px;
    z-index: 999999;
    position: fixed;

    @include media-breakpoint-down(sm) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 12px;
        padding-right: 12px;
    }

    .tnlink {
        color: $green !important;

        &:hover {
            color: $green2 !important
        }
    }
}