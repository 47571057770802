@use "sass:math";
@import "~bootstrap/scss/bootstrap-reboot";
@import "@wolfiesports/srm-component/dist/styles/colors";

$desktop-max-width: 9999px;
$tablet-max-width: 1299px;
$mobile-max-width: 820px;
$website-max-width: 1320px;

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: $mobile-max-width) {
      @content;
    }
  }
}

@mixin nc-scrollbar($size: 6px, $color: $light-grey) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;

    @include media-breakpoint-down(sm) {
      width: math.div($size, 3);
      height: math.div($size, 3);
    }
  }
  &::-webkit-scrollbar-thumb {
    background: $color;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }

  & {
    @if $size > 6 {
      scrollbar-width: auto;
    } @else {
      scrollbar-width: thin;
    }

    @include media-breakpoint-down(sm) {
      scrollbar-width: thin;
    }
  }
}

@mixin line-clamp($lines, $line-height) {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  max-height: $lines * $line-height;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin hidden-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}
