@import "~@wolfiesports/srm-component/dist/styles/colors";
@import "~@wolfiesports/srm-component/dist/styles/mixins";

@mixin notification-icon($size, $margin-right, $img-padding, $border-color) {
  flex-shrink: 0;
  min-width: $size;
  min-height: $size;
  margin-right: $margin-right;
  border-radius: 100%;

  > img,
  > .thematic-icon {
    object-fit: contain;
    width: $size;
    height: $size;
  }
}

.color-grey {
  color: $primary-color;
}

.color-red {
  color: $red;
}

.no-notifications {
  color: $secondary-color-light;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  margin: auto;
}

.notification-button {
  @include media-breakpoint-down(md) {
    width: 50%;
  }

  &:hover {
    border-radius: unset;
  }

  i {
    font-size: 20px;
    color: $white;
    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }
}

$grid-padding: 90px;
.notifications-request-container {
  background-color: transparent;
  min-height: 325px;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;

  .notification-container-show-more {
    color: $white;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
  }

  .notification-container {
    display: flex;
    flex-direction: column;
    color: $white;
    background-color: transparent;

    &.highligthed {
      background-color: $secondary-color-mid;

      &:hover {
        background-color: $secondary-color-dark;
      }
    }

    .first-line {
      min-height: 55px;
      padding: 0.55rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: $secondary-color-light solid 1px;

      .tournament-underline {
        text-decoration: underline;
      }

      .notification-icon {
        @include notification-icon(40px, 5px, 6px, $secondary-color-light);

        .icon-tournament {
          width: 40px;
          height: 40px;
          background-color: $red;
        }
      }

      .notification-icon-secondary {
        @include notification-icon(40px, 10px, 4px, $primary-color);
      }

      .notification-body {
        display: flex;
        flex-grow: 1;

        span {
          align-self: center;
          flex-wrap: wrap;
        }

        .notification-title {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .clickable {
            font-weight: bold;
            cursor: pointer;
            text-decoration: underline;
            color: $white;
            align-self: center;
          }
        }

        .notification-action {
          cursor: pointer;
          display: flex;
          align-items: center;

          > span {
            margin: 5px;
          }
        }
      }
    }

    .notification-date {
      color: $secondary-color-light;
      align-self: flex-end;
    }
  }
}

.underline {
  text-decoration: underline;
}

.see-more {
  color: $white;
  display: flex;
  justify-content: center;
}

.clickable {
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .notification-icon {
    display: none;
  }
  .notification-icon-secondary {
    display: none;
  }
}

.match-button {
  height: 40px;
}

.premium-coin-notification {
  color: $premium-primary-color;

  .coin-notification-img {
    width: 50px;
    height: 50px;
  }

  .premium-coin-notification-img {
    width: 20px;
    height: 20px;
  }
}

.premium-coin-notification-calc {
  height: 25px;
  background-color: $grey3;
  color: $white;
  border-radius: 15px;
  padding: 2px 10px;
}

.notification-list-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: $secondary-color-dark;
  border: 1px solid $secondary-color-light;
  max-width: 1200px;
  margin-top: 10px !important;
  margin: auto;
  overflow-y: auto;
}
