@import "@wolfiesports/srm-component/dist/styles/colors";

.user-informations {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $grey5;
  margin-top: 125px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  .userinfos-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    .name-container {
      width: 100%;

      .username-text {
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 45px;
        justify-content: center;
        display: flex;
        width: 100%;
      }

      .username-code {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        justify-content: center;
        display: flex;
        width: 100%;
        color: $electric-lime;
      }
    }

    .usersince-container {
      width: 100;
      margin-top: 20px;

      .user-membersince {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        justify-content: center;
        display: flex;
        width: 100%;
        color: $grey5;
        padding-bottom: 15px;
      }
    }
  }

  .user-informations-edit {
    position: absolute;
    right: 15px;
    top: 140px;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .user-biography {
    width: 85%;
    flex-direction: column;
    align-items: center;
    display: flex;

    .biography-title {
      justify-content: center;
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: $grey5;
    }

    .biography-text {
      justify-content: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      display: flex;
      width: 100%;
      color: $grey6;
    }
  }

  .userinfos-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    width: 85%;

    .ds-input-container,
    .ds-input,
    input {
      width: 100%;
    }

    .text-area {
      border-radius: 10px;
      color: $white;
    }
  }
}
