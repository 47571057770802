@import "@wolfiesports/srm-component/dist/styles/colors";

.onboarding-page-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background-attachment: fixed;
  background-image: url(https://bundle.next.wolfiesports.com/webapp-gameaccount/static/media/game-account-background.3ce66e4709e1519b84dc.jpeg);
  background-position: 50%;
  background-size: cover;
  height: 100%;
  min-height: calc(100vh - 48px - 56px); // substract header & footer
  padding: 32px;
  position: relative;
  width: 100%;

  h3 {
    color: $grey6;
  }

  .stepper-container {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .step {
      width: 10px;
      height: 10px;
      background-color: $grey5;
      border-radius: 100%;
      transition: 0.2s;

      &.active {
        transition: 0.2s;
        background-color: $electric-lime;
      }
    }
  }

  .favorite-games-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 15px;

    .selection {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      overflow: auto;
      justify-content: center;

      .item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;

        &.active .card .card-background {
          filter: none;
        }
        .card {
          margin-top: 10px;
          box-sizing: border-box; // Add this line
          background-color: $grey1;

          margin-left: 10px;
          height: 140px;
          width: 295px;
          border-radius: 10px;
          border: 2px $grey5 solid;
          transition: 0.2s;
          position: relative;

          &:hover {
            border: 2px $electric-lime solid;
            transition: 0.2s;
          }

          .card-background {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            filter: brightness(0.6);
            transition: 0.2s;
          }

          &:hover .card-background {
            filter: none;
            transition: 0.2s;
          }

          @media screen and (max-width: 1000px) {
            height: 75px;
            width: 150px;
          }
        }

        .card-logo {
          width: 65px;
          height: 65px;
          position: absolute;
          margin-top: 50px;
          z-index: 10;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media screen and (max-width: 1000px) {
            width: 35px;
            height: 35px;
            margin-top: 30px;
            margin-left: 10px;
          }
        }

        &.active .card {
          border: 2px $electric-lime solid;
          box-shadow: 0px 0px 13px $electric-lime;
        }
      }
    }

    .send-button {
      display: flex;
      justify-content: center;
    }
  }

  .game-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 15px;

    .forms-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .form-item {
        display: flex;
        gap: 8px;

        .game-logo {
          width: 55px;
          height: 55px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  .account-settings-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;

    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .input-styles {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
      }

      .input-styles-checkbox {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-self: center;

        h6 {
          margin-bottom: 0;
        }

        .custom-checkbox {
          display: inline-block;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          font-size: 22px;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 5px;

            &:hover {
              background-color: #ccc;
            }
          }

          input:checked ~ .checkmark {
            background-color: $electric-lime;
          }

          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            -webkit-transform: rotate(38deg);
            -ms-transform: rotate(38deg);
            transform: rotate(38deg);
            left: 9px;
            top: 4px;
            width: 7px;
            height: 14px;
            border: solid $black;
            border-width: 0 2px 2px 0px;
          }
        }
      }

      .inline-form {
        display: flex;
        flex-direction: row;
        gap: 16px;

        @media screen and (max-width: 1000px) {
          flex-direction: column;
        }
      }

      .game-selection {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        overflow: auto;

        .game-item {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;

          .game-card {
            margin-top: 10px;
            margin-left: 10px;
            height: 200px;
            width: 140px;
            border-radius: 10px;
            border: 1px $grey5 solid;
            transition: 0.2s;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @media screen and (max-width: 1000px) {
              height: 100px;
              width: 75px;
            }

            &:hover {
              transition: 0.2s;
              border: 1px $electric-lime solid;
            }
          }

          &.active {
            .game-card {
              transition: 0.2s;
              border: 2px $electric-lime solid;
              box-shadow: 0px 0px 13px $electric-lime;
            }
          }
        }
      }

      .profile-selection {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        overflow: auto;
        align-self: center;

        .profile-item {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .profile-card {
            height: 150px;
            width: 150px;
            border-radius: 100%;
            border: 2px $grey5 solid;
            margin: 13px;

            @media screen and (max-width: 1000px) {
              height: 95px;
              width: 95px;
            }

            &:hover {
              transition: 0.2s;
              border: 2px $electric-lime solid;
            }
          }

          &.active {
            .profile-card {
              transition: 0.2s;
              border: 2px $electric-lime solid;
              box-shadow: 0px 0px 13px $electric-lime;
            }
          }
        }
      }

      .banner-selection {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        align-self: center;
        justify-content: center;
        @media screen and (max-width: 1000px) {
          justify-content: center;
        }

        .banner-item {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .banner-card {
            height: 200px;
            width: 650px;
            border-radius: 10px;
            border: 1px $grey5 solid;
            transition: 0.2s;
            margin: 10px;

            @media screen and (max-width: 1000px) {
              height: 100px;
              width: 350px;
            }

            @media screen and (max-width: 475px) {
              height: 50px;
              width: 175px;
            }
            &:hover {
              transition: 0.2s;
              border: 1px $electric-lime solid;
            }
          }

          &.active {
            .banner-card {
              transition: 0.2s;
              border: 1px $electric-lime solid;
              box-shadow: 0px 0px 10px $electric-lime;
            }
          }
        }
      }

      .currency-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .item {
          display: flex;
          flex-direction: row;
          gap: 8px;
        }
      }

      .send-button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
