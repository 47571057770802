@import "@wolfiesports/srm-component/dist/styles/colors";

.user-profile {
  .dialog-content {
    width: 1200px;

    @media screen and (max-width: 1000px) {
      width: 750px;
    }

    @media screen and (max-width: 750px) {
      width: 350px;
    }
    .dialog-body {
      width: 100% !important;
    }
  }

  .profile-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-content: center;
    justify-content: center;

    .profile-item {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;

      img {
        max-width: 95%;
        height: auto;
        border: 2px transparent solid;
        border-radius: 100%;
      }
      &.active {
        img {
          border: 2px $electric-lime solid;
          box-shadow: 0px 0px 13px $electric-lime;
        }
      }
    }
  }

  .banner-selection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-content: center;
    justify-content: center;

    .banner-item {
      width: 100%;
      z-index: 24;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 95%;
        height: auto;
        border: 2px transparent solid;
      }

      &.active {
        img {
          border: 2px $electric-lime solid;
          box-shadow: 0px 0px 13px $electric-lime;
        }
      }
    }
  }
  .apply-button {
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    display: flex;
  }
}
