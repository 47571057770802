@import "@wolfiesports/srm-component/dist/styles/colors";
@import '../../styles/mixins.scss';

.tournament-history-title {
    text-transform: uppercase;
    color: $grey5;
}

.tournament-history-table {
    width: 100%;
    border-collapse: collapse;
    color: white; // Adjust if needed

    thead tr th:first-child,
    tbody tr td:first-child {
        @include breakpoint(mobile) {
            width: 85px;
            min-width: 85px;
            max-width: 85px;
            word-break: break-all;
        }
    }

    tbody tr td{
        .tournament-entry img {
            width: 24px;
            height: 24px;
            max-width: 24px;
            max-height: 24px;
            margin-right:10px;
        }
    }

    th {
        background-color: #1a1a1a; // Adjust to match the header background color in the image
        padding: 12px 16px;
        text-align: left;
        font-weight: normal;

        @include breakpoint(mobile) {
            padding: 10px 10px;
        }

        &:first-child {
            border-top-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
        }
    }

    td {
        background-color: #2a2a2a; // Adjust to match the row background color in the image
        padding: 12px 16px;
        border-top: 1px solid #1a1a1a; // Adjust to match the border color in the image
    }

    tr:hover {
        background-color: #323232; // Adjust for hover effect color if needed
    }

    tr {
        &:last-child {
            td {
                &:first-child {
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }

        &:hover {
            td {
                background-color: $grey4;
                transition: 0.2s;
            }
        }
    }

    .game-image {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        background-color: $grey4;
        background-position: center;
        background-size: cover;
    }
}