@import "@wolfiesports/srm-component/dist/styles/colors";
@import "react-phone-number-input/style.css";

.premium-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 750px;
  margin-bottom: 50px;

  @media screen and (min-width: 750px) {
    justify-content: space-evenly;
    min-width: 550px;
    min-height: 250px;
  }

  .stepper-container {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .step {
      width: 10px;
      height: 10px;
      background-color: $grey5;
      border-radius: 100%;
      transition: 0.2s;

      &.active {
        transition: 0.2s;
        background-color: $electric-lime;
      }

      &.error {
        background-color: $amaranth;
      }
    }
  }

  .payment-method-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .button-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 250px;
    }

    .cards-img-mpesa {
      width: 10px;
    }
  }

  .button-label-container {
    display: flex;
    flex-direction: row;
    gap: 16px;

    p {
      margin-bottom: 0;
    }
    &.credit-card {
      img {
        width: 42px;
        height: 14px;
      }
    }

    &.paypal {
      img {
        width: 61px;
        height: 14px;
      }
    }
    &.mpesa {
      img {
        height: 18px;
      }
    }
  }

  .stripe-checkout-form {
    h5 {
      color: white;
      text-align: center;
      margin-bottom: 25px;
    }
    .stripe-form-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .adress-form-part-two-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      @media screen and (max-width: 450px) {
        flex-direction: column;
      }
    }

    .adress-form-container {
      display: flex;
      flex-direction: row;
      gap: 16px;

      @media screen and (max-width: 450px) {
        flex-direction: column;
      }
    }

    .card-element-container {
      margin-top: 16px;
      border: 1px solid #4f4f4f;
      padding: 16px;
      border-radius: 5px;
    }

    .button-part {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 16px;

      .button-container {
        .button {
          width: 150px;
        }
      }

      @media screen and (max-width: 450px) {
        flex-direction: column;

        .button-container {
          .button {
            width: 100%;
          }
        }
      }
    }
  }
}

.selection-plan-container {
  display: flex;
  gap: 20px;
  margin-top: 25px;
  flex-direction: column;

  .button-container {
    .button {
      width: 100%;
    }
  }
}

.success-error-step-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  text-align: center;
  margin-top: 25px;

  .button-container {
    align-self: center;
  }
}

.paypal-payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .paypal-hidden {
    display: none;
    width: 100%;
  }

  .button-container {
    .button {
      width: 100%;
    }
  }
}

.loading-mpesa {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.premium-loader {
  border: 5px solid $grey5;
  border-top: 5px solid $electric-lime;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(00deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PhoneInput {
  width: 100%;
  border-radius: 4px;

  .PhoneInputCountry {
    border-top: 1px solid $grey4;
    border-bottom: 1px solid $grey4;
    border-left: 1px solid $grey4;
    margin-right: 0;
    padding: 10px;
    background-color: $grey2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  input {
    background: transparent;
    border: 1px solid $grey4;
    padding: 16px;
    background-color: $grey2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: 0.2s;
    color: white;

    &:focus {
      outline: none;
      border: 1px solid $grey5;
      transition: 0.2s;
    }
  }
}
